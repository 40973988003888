/* global VXConfig */
import PjaxWrapper from "./PjaxWrapper";
import History     from "./History";

const ReloadHelper = {
	reload: (url, e) => {
		if (VXConfig.isVXMobilePlatform) {
			ReloadHelper.reloadWithReactRouter(url, e);
		} else {
			ReloadHelper.reloadWithPjax(url, e);
		}
	},

	/**
	 * @param {Boolean} usePjax
	 */
	reloadCurrentPage: (usePjax = true) => {
		if (true === usePjax) {
			PjaxWrapper.reloadCurrentPage();
		} else {
			window.location.reload();
		}
	},

	reloadWithPjax: (url, e) => {
		if (window._pjaxInstance && document.body) {
			const isEventObject        = typeof e === 'object' && !!e;
			const openInNewTabOrWindow = isEventObject && (e.button === 1 || e.ctrlKey || e.shiftKey);
			if (isEventObject) {
				if (openInNewTabOrWindow) {
					e.stopPropagation(); // Do not prevent default because that opens the url in a new tab or window
				} else if (e.cancelable && !e.isDefaultPrevented() && !e.isPropagationStopped() && e.stopPropagation) {
						e.stopPropagation();
						e.preventDefault();
					}
			}

			if (!openInNewTabOrWindow) {
				PjaxWrapper.reload(url, e);
			}
		} else {
			window.open(url, '_self');
		}
	},

	reloadWithReactRouter: (url, e, replace = false) => {
		const isEventObject        = typeof e === 'object' && !!e;
		const openInNewTabOrWindow = isEventObject && (e.button === 1 || e.ctrlKey || e.shiftKey);
		if (isEventObject) {
			if (openInNewTabOrWindow) {
				e.stopPropagation(); // Do not prevent default because that opens the url in a new tab or window
			} else if (e.cancelable && !e.isDefaultPrevented() && !e.isPropagationStopped() && e.stopPropagation) {
					e.stopPropagation();
					e.preventDefault();
				}
		}

		if (!openInNewTabOrWindow) {
			if (replace) {
				History.replace(url);
			} else {
				History.push(url);
			}
		}
	},
};

export default ReloadHelper;
